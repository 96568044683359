import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DashboardIcon } from "../../constants/utils/svgs";
import BenefitsTab from "../BenefitsTab";
import BusinessesTab from "../BusinessesTab";
import ContactUsTab from "../ContactUsTab";
import EnquiryTab from "../EnquiryTab";
import CampaignsTab from "../CampaignsTab";
import ChangePassword from "../ChangePassword";
import Dashboard from "../Dashboard";
import Footer from "../Footer";
import Navbar from "../Navbar";
import RSVPTab from "../RSVPTab";
import Sidebar from "../Sidebar/index";
import Table from "../Table";
import "./style.css";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const [rsvpEventID, setRsvpEventID] = useState({
    id: "",
    name: "",
    type: "",
  });

  // Define breadcrumbs data for each tab
  const tabBreadcrumbs: { [key: string]: string } = {
    dashboard: "Dashboard",
    applications: "Applications",
    members: "Members",
    businesses: "Businesses",
    contactUs: "Contact Us",
    enquiry: "Enquiry",
    events: "Events",
    benefits: "Benefits",
    attendees: "Attendees",
  };

  //render component on selecting tab from sidebar
  const tabComponents: { [key: string]: React.ReactNode } = {
    dashboard: <Dashboard />,
    applications: <Table />,
    members: <Table />,
    businesses: <BusinessesTab />,
    contactUs: <ContactUsTab />,
    enquiry: <EnquiryTab />,
    events: <CampaignsTab setRsvpEventID={setRsvpEventID} />,
    benefits: <BenefitsTab />,
    attendees: <RSVPTab rsvpEventID={rsvpEventID} />,
    changePassword: <ChangePassword />,
  };

  //navigate to active tab's component
  useEffect(() => {
    if (tab !== "attendees") {
      setRsvpEventID({
        id: "",
        name: "",
        type: "",
      });
    }
  }, [tab, navigate, setRsvpEventID]);

  return (
    <section>
      <div className="homepage-section">
        <div className="row">
          <div className="sidebar-container">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <div className="content-container">
            <div className="content">
              <Navbar breadcrumbs={tabBreadcrumbs[tab as string]} />
              {tabComponents[tab as string]}
              {/* Render component based on the active tab */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePage;
